import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import pinterest from "../img/social/pinterest.svg";

export const AboutPageTemplate = ({ title, content, contentComponent }) => {
	const PageContent = contentComponent || Content;

	return (
		<section
			className="section section--gradient"
			style={{ backgroundColor: "#FFF3E9" }}
		>
			<div className="container">
				<div className="columns">
					<div className="column is-10 is-offset-1">
						<div className="section">
							<h1 className="title is-size-3 has-text-weight-bold is-bold-light">
								{title}
							</h1>
							<PageContent className="content" content={content} />
							<div className="social" style={{ textAlign: "center" }}>
								<a title="facebook" href="https://facebook.com/astrokeoflife">
									<img
										src={facebook}
										alt="Facebook"
										style={{ width: "1em", height: "1em" }}
									/>
								</a>
								<a
									title="Stroke Art Instagram"
									href="https://www.instagram.com/str.okeart/"
								>
									<img
										src={instagram}
										alt="Stroke Art Instagram"
										style={{ width: "1em", height: "1em" }}
									/>
								</a>
								<a title="Pinterest" href="https://pinterest.com/astrokeoflife">
									<img
										src={pinterest}
										alt="Pinterest"
										style={{ width: "1em", height: "1em" }}
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

AboutPageTemplate.propTypes = {
	title: PropTypes.string.isRequired,
	content: PropTypes.string,
	contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<AboutPageTemplate
				contentComponent={HTMLContent}
				title={post.frontmatter.title}
				content={post.html}
			/>
		</Layout>
	);
};

AboutPage.propTypes = {
	data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
	query AboutPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			html
			frontmatter {
				title
			}
		}
	}
`;
